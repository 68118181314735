<template>
    <a-card>
        <a-page-header
            title="创意标签模板管理"
        />

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                        <a-button type="primary" @click="jumpDetailPage(0)">新建模板</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            class="mt-20"
        >
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <base-button
                    :type="'link'"
                    :title="'修改'"
                    @onClickBtn="jumpDetailPage(record.id)"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <div>
            <div class="mt-20">PS：</div>
            <div>1、系统每天凌晨更新广告计划的创意标签</div>
            <div>2、1个车系或品牌只能创建1条创意标签模版</div>
        </div>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                principalIdList: [],
                customerSceneList: [],
                carSeriesIdList: []
            },
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '标签',
                    // dataIndex: 'creativeLabelArr',
                    customRender: (text, row, index) => {
                        let txt = ''
                        row.creativeLabelArr.map(item => {
                            txt += `${item} `
                        })
                        return row.creativeLabelArr.length > 0 ? <div style="word-break: keep-all;">{txt}</div> : '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
                {
                    align: 'center',
                    title: '最新更新时间',
                    dataIndex: 'mtime',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            principalList: [],
            seriesList: [],
            customerSceneList: [],
        }
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getCustomerScene()
        this.getDataList()
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                principalIdList: [],
                customerSceneList: [],
                carSeriesIdList: []
            }
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = {
                principalIdList: _form.principalIdList.toString(),
                carSeriesIdList: _form.carSeriesIdList.toString(),
                size: this.pagination.pageSize,
                page: this.pagination.current,
            }
            this.$api.core.materialManage.getTagTemplateList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id) {
            this.$router.push({
                path: `/creativeTagManage/detail`,
                query: {
                    id,
                }
            })
        },
        updateState(record) {
            let params = {
                creativeTitleStatus: record.creativeTitleStatus == 1 ? 2 : 1,
                creativeTitleTemplateId: record.id,
            }
            this.$api.core.materialManage.updateCreativeTitle(params).then((res) => {
                if(res.code == 200) {
                    this.getDataList()
                } else {
                    console.error(`获取列表失败${res}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}

.mt-20 {
    margin-top: 20px;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.link {
    text-decoration: underline;
}
</style>